<template>
  <div id="app">
    <Header />
    <div class="container" style="margin-top:50px;">
      <h4 class="left-align">Mon compte</h4>
      <div class="col m12 z-depth-3">
        <content-loader
          v-if="skeletonVisible"
          :width="340"
          :height="84"
          :speed="2"
          primaryColor="#f3f3f3"
          secondaryColor="#3a50b3"
        >
          <rect x="10" y="10" rx="3" ry="3" width="67" height="11" />
          <rect x="86" y="10" rx="3" ry="3" width="140" height="11" />
          <rect x="127" y="58" rx="3" ry="3" width="53" height="11" />
          <rect x="187" y="58" rx="3" ry="3" width="72" height="11" />
          <rect x="18" y="58" rx="3" ry="3" width="100" height="11" />
          <rect x="235" y="10" rx="3" ry="3" width="37" height="11" />
          <rect x="18" y="33" rx="3" ry="3" width="140" height="11" />
          <rect x="166" y="33" rx="3" ry="3" width="153" height="11" />
        </content-loader>
        <div v-if="!skeletonVisible" class="row table ">
          <div class="container">
            <div class="row premiere">
              <div class="input-field col s6">
                <input
                  placeholder="first_name"
                  v-model="first_name"
                  id="first_name2"
                  type="text"
                  class="validate"
                  :disabled="!edit_mode"
                />
                <label class="active" for="first_name2">Prénom</label>
              </div>

              <div class="input-field col s6">
                <input
                  placeholder="last_name"
                  v-model="last_name"
                  id="last_name2"
                  type="text"
                  class="validate"
                  :disabled="!edit_mode"
                />
                <label class="active" for="first_name2">Nom</label>
              </div>
            </div>
            <div class="row">
              <div class="input-field col s6">
                <input
                  placeholder="phone_number"
                  v-model="phone_number"
                  id="phone_number2"
                  type="text"
                  class="validate"
                  :disabled="!edit_mode"
                />
                <label class="active" for="phone_number2"
                  >Numéro de téléphone
                </label>
              </div>

              <div class="input-field col s6">
                <input
                  placeholder="adresse"
                  v-model="adresse"
                  id="adresse2"
                  type="text"
                  class="validate"
                  :disabled="!edit_mode"
                />
                <label class="active" for="adresse2">Adresse civil</label>
              </div>
            </div>
            <div class="row">
              <div class="input-field col s6">
                <input
                  placeholder="email"
                  v-model="email"
                  id="email2"
                  type="text"
                  class="validate"
                  :disabled="!edit_mode"
                />
                <label class="active" for="email2">Adresse courriel</label>
              </div>

              <div class="input-field col s6">
                <input
                  placeholder="member_since"
                  v-model="member_since"
                  id="member_since2"
                  type="text"
                  class="validate"
                  :disabled="!edit_mode"
                />
                <label class="active" for="member_since2">Membre depuis</label>
              </div>
            </div>

            <button
              v-if="!edit_mode"
              @click="modifyAccount"
              class="login-button bold-text animated slideInRight"
            >
              <!-- <div class="lds-ring-container">
                <div class="lds-dual-ring"></div>
              </div> -->
              modifier
            </button>
            <button
              v-if="edit_mode"
              @click="updateAuth"
              class="login-button bold-text"
            >
              <!-- <div class="lds-ring-container">
                <div class="lds-dual-ring"></div>
              </div> -->
              enregistrer
            </button>

            <button
              class="button-mode-detail animated slideInRight slow"
              v-if="edit_mode"
              @click="cancel"
            >
              annuler
            </button>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import { Auth } from "aws-amplify";
import { ContentLoader } from "vue-content-loader";

export default {
  name: "Profil",
  components: {
    Header,
    Footer,
    ContentLoader,
  },

  data() {
    return {
      skeletonVisible: true,
      current_user: this.$store.state.user,
      first_name: "",
      last_name: "",
      phone_number: "",
      adresse: "",
      email: "",
      member_since: "",
      edit_mode: false,
    };
  },

  async created() {
    setTimeout(() => (this.skeletonVisible = false), 2000);
    await this.$Amplify.Auth.currentAuthenticatedUser();
    this.$store.state.user = await Auth.currentAuthenticatedUser();
  },

  mounted: function() {
    var _this = this;

    Auth.userAttributes(this.$store.state.user)
      .then((result) => {
        result.forEach(function(item) {
          switch (item.Name) {
            case "custom:first_name":
              _this.first_name = item.Value;
              break;
            case "custom:last_name":
              _this.last_name = item.Value;
              break;
            case "custom:phone_number":
              _this.phone_number = item.Value;
              break;
            case "custom:adresse":
              _this.adresse = item.Value;
              break;
            case "custom:member_since":
              _this.member_since = item.Value;
              break;
            case "email":
              _this.email = item.Value;
              break;
          }
        });
      })
      .catch((err) => console.log(err));
  },

  methods: {
    modifyAccount() {
      this.edit_mode = true;
    },
    updateAuth() {
      this.edit_mode = false;

      var attributes = {
        "custom:phone_number": this.phone_number,
        "custom:first_name": this.first_name,
        "custom:last_name": this.last_name,
        "custom:adresse": this.adresse,
        "custom:member_since": this.member_since,
      };

      Auth.updateUserAttributes(this.$store.state.user, attributes)
        .then((result) => {
          console.log(result);
        })
        .catch((err) => console.log(err));
    },
    cancel(){
      this.edit_mode = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.premiere {
  padding-top: 30px;
}
button {
  margin-bottom: 30px;
}
.button-mode-detail {
  margin-left: 30px;
  background: transparent;
  border: 0px;
  color: #3a50b3;
}
.button-mode-detail:hover {
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: #3a50b3;
}
</style>
